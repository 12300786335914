<script setup lang="ts">
</script>

<template>
  <Navbar></Navbar>
  <slot />
</template>

<style lang="scss" scoped>
@import "@/assets/css/frontend_vars.scss";

.container {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: $responsiveWidth;
  max-height: $responsiveHeight;
}
</style>
